<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>我的订单</span></div>

			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="全部订单" name="all"></el-tab-pane>
				<el-tab-pane label="待申请报价单" name="waitApplyQuotation"></el-tab-pane>
				<el-tab-pane label="待生成报价单" name="waitGenerateQuotation"></el-tab-pane>
				<el-tab-pane label="待确认报价单" name="waitConfirmQuotation"></el-tab-pane>
				<el-tab-pane label="待申请合同" name="waitApplyContract"></el-tab-pane>
				<el-tab-pane label="待生成合同" name="waitGenerateContract"></el-tab-pane>
				<el-tab-pane label="待确认合同" name="waitConfirmContract"></el-tab-pane>
				<el-tab-pane label="待上传付款附件" name="waitUploadPayment"></el-tab-pane>
				<el-tab-pane label="待确认付款凭证" name="waitConfirmPayment"></el-tab-pane>
			</el-tabs>

			<div v-loading="loading">
				<nav>
					<li>商品信息</li>
					<li>单价</li>
					<li>数量</li>
					<li>实付款</li>
					<li>订单状态</li>
					<li>操作</li>
				</nav>

				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
							<span class="order-no">订单号：{{ orderItem.order_no }}</span>
							<router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name
								}}</router-link>
							<span class="order-type">{{ orderItem.order_type_name }}</span>
						</div>
						<ul v-for="(goodsItem, goodsIndex) in orderItem.order_goods" :key="goodsIndex">
							<li>
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<img :src="$img(goodsItem.sku_image, { size: 'mid' })"
										@error="imageError(orderIndex, goodsIndex)" />
								</div>
								<div class="info-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<h5>{{ goodsItem.sku_name }}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
							</li>
							<li>
								<span>￥{{ goodsItem.price }}</span>
							</li>
							<li>
								<span>{{ goodsItem.num }}</span>
							</li>
							<li>
								<span>￥{{ orderItem.pay_money }}</span>
							</li>
							<template v-if="goodsIndex == 0">
								<li>
									<span class="ns-text-color">{{ orderItem.order_status_name }}</span>
									<el-link :underline="false" @click="orderDetail(orderItem)">订单详情</el-link>
								</li>
								<li>
									<template>
										<el-button type="primary" size="mini" plain v-if="orderItem.is_evaluate == 1"
											@click="operation('memberOrderEvaluation', orderItem)">
											<template v-if="orderItem.evaluate_status == 0">
												评价
											</template>
											<template v-else-if="orderItem.evaluate_status == 1">
												追评
											</template>
										</el-button>

										<el-button type="primary" size="mini"
											v-for="(operationItem, operationIndex) in orderActions[orderItem.order_status]"
											:key="operationIndex" @click="operation(operationItem.action, orderItem)">
											{{ operationItem.title }}
										</el-button>
									</template>

								</li>
							</template>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</el-card>
		<el-dialog title="上传支付凭证" :visible.sync="dialogVisible" width="30%" center>
			<el-upload class="upload-demo" style="text-align: center;" drag :action="uploadAction"
				:on-success="onUploadSuccess" :data="uploadData">
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			</el-upload>
		</el-dialog>

	</div>
</template>

<script>
import Config from "@/utils/config.js"
import {
	getToken
} from "@/utils/auth"
import { exportQuotation } from "@/api/order/payment"
import { mapGetters } from 'vuex';
import { apiOrderList, uploadPayment } from '@/api/order/order';
import orderMethod from '@/utils/orderMethod';
export default {
	name: 'order_list',
	components: {},
	data: () => {
		return {
			dialogVisible: false,
			orderStatus: 'all',
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			uploadData: {
				token: getToken()
			},
			operatingOrder: null,
			yes: true,
			uploadAction: Config.baseUrl + '/api/upload/paymentImg',
			statusMap: {
				'0': 'waitApplyQuotation',
				'5': 'waitGenerateQuotation',
				'6': 'waitConfirmQuotation',
				'7': 'waitApplyContract',
				'8': 'waitGenerateContract',
				'9': 'waitConfirmContract',
				'11': 'waitUploadPayment',
				'12': 'waitConfirmPayment'
			},
			orderActions: {
				'0': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				},
				{
					title: '申请报价单',
					action: 'applyQuotation',
					isRed: true
				}
				],
				'5': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				},
				{
					title: '导出报价单',
					action: 'exportQuotation',
					isRed: true
				}
				],
				'6': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				},
				{
					title: '查看报价单',
					action: 'exportQuotation',
					isRed: false
				}, {
					title: '确认',
					action: 'confirmQuotation',
					isRed: true
				}
				],
				'7': [{
					title: '查看报价单',
					action: 'exportQuotation',
					isRed: false
				},
				{
					title: '申请合同',
					action: 'applyContract',
					isRed: true
				}
				],
				'8': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				}, {
					title: '查看报价单',
					action: 'exportQuotation',
					isRed: false
				}],
				'9': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				}, {
					title: '查看合同',
					action: 'previewContract',
					isRed: false
				},
				{
					title: '确认',
					action: 'confirmContract',
					isRed: true
				}
				],
				'11': [{
					title: '取消订单',
					action: 'cancel',
					isRed: false
				}, {
					title: '查看报价单',
					action: 'exportQuotation',
					isRed: false
				},
				{
					title: '查看合同',
					action: 'previewContract',
					isRed: false
				},
				{
					title: '上传付款凭证',
					action: 'uploadPayment',
					isRed: true
				}
				],
				'12': [{
					title: '查看报价单',
					action: 'exportQuotation',
					isRed: false
				},
				{
					title: '查看合同',
					action: 'previewContract',
					isRed: false
				}
				]
			}
		};
	},
	mixins: [orderMethod],
	created() {
		this.orderStatus = this.$route.query.status || 'all';
		this.getOrderList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function () {
			self.yes = false
		}, 300)
	},
	methods: {
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getOrderList() {
			apiOrderList({
				page: this.currentPage,
				page_size: this.pageSize,
				order_status: this.orderStatus
			})
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						list = res.data.list;
						this.total = res.data.count;
					}
					this.orderList = list;
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		operation(action, orderData) {
			let index = this.status;
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(orderData);
					break;
				case 'orderClose': //关闭
					this.orderClose(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'memberTakeDelivery': //收货
					this.orderDelivery(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({ path: '/member/logistics', query: { order_id: orderData.order_id } });
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({ path: '/evaluate', query: { order_id: orderData.order_id } });
					break;
				case 'extendTakeDelivery': //延长收货
					this.orderExtendDelivery(orderData, () => {
						this.refresh();
					});
					break;

				case 'applyQuotation': //申请报价单
					this.userApplyQuotation(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'confirmQuotation': //确认报价单
					this.userConfirmQuotation(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'applyContract': //申请合同
					this.userApplyContract(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'exportQuotation': //查看报价单
					if (orderData.quotation_url) {
						window.location.href = orderData.quotation_url
					} else {
						exportQuotation(orderData).then((res) => {
							window.location.href = res.data.url
						})
					}
					break;
				case 'confirmContract': //确认合同
					this.userConfirmContract(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'previewContract': //查看合同
					if (orderData.contract_url) {
						window.location.href = orderData.contract_url
					} else {
						this.$message({
							message: "合同尚未生成",
							type: "error"
						})
					}
					break;
				case 'uploadPayment': //上传付款附件
					this.dialogVisible = true
					this.operatingOrder = orderData.order_id
					break;
			}
		},
		onUploadSuccess(response, file, fileList) {
			console.log(response, file)
			uploadPayment({
				order_id: this.operatingOrder,
				payment_img: response.data.path
			}).then((res) => {
					this.$message({
						message: "支付凭证上传成功",
						type: "success"
					})
					this.dialogVisible = false
					this.refresh();
				}).catch(error => {
					this.$message({
						message: JSON.stringify(error),
						type: "error"
					})
					this.dialogVisible = false
				})
		},
		orderDetail(data) {
			switch (parseInt(data.order_type)) {
				case 2:
					// 自提订单
					this.$router.push({ path: '/member/order_detail_pickup', query: { order_id: data.order_id } });
					break;
				case 3:
					// 本地配送订单
					this.$router.push({ path: '/member/order_detail_local_delivery', query: { order_id: data.order_id } });
					break;
				case 4:
					// 虚拟订单
					this.$router.push({ path: '/member/order_detail_virtual', query: { order_id: data.order_id } });
					break;
				default:
					this.$router.push({ path: '/member/order_detail', query: { order_id: data.order_id } });
					break;
			}
		},
		imageError(orderIndex, goodsIndex) {
			this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;

		li {
			float: left;

			&:nth-child(1) {
				width: 45%;
			}

			&:nth-child(2) {
				width: 10%;
			}

			&:nth-child(3) {
				width: 10%;
			}

			&:nth-child(4) {
				width: 10%;
			}

			&:nth-child(5) {
				width: 15%;
			}

			&:nth-child(6) {
				width: 10%;
			}
		}
	}

	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;

			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 10px;
				}

				border-bottom: 1px solid #eeeeee;

				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 30px;
				}
			}
		}

		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;

			li {
				float: left;
				line-height: 60px;

				&:nth-child(1) {
					width: 45%;
					line-height: inherit;

					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}

					.info-wrap {
						margin-left: 70px;

						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;

							&:hover {
								color: $base-color;
							}
						}

						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 15%;
					line-height: 30px;

					a {
						display: block;
					}
				}

				&:nth-child(6) {
					width: 10%;
					line-height: initial;

					button {
						margin-left: 0;
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
</style>
